<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info flex-center">
          <span class="grid-heading">
            <h1>{{ $t('Pickers') }}</h1>
            <span class="entries"
              >{{ NumberFormat(list.entries) }}
              {{ $t(`entr${list.entries == 1 ? 'y' : 'ies'}`) }}</span
            >
          </span>
          <div class="grid-actions">
            <div
              v-if="!list.period.cached"
              class="date-range flex-row"
              style="width: auto"
            >
              <div class="grid-date">
                <p class="grid-heading" style="color: inherit">
                  {{ $t('Start date') }}
                </p>
                <div class="date-range flex-row">
                  <div class="input">
                    <VueCtkDateTimePicker
                      id="list-period-date-range-start"
                      @input="SearchDate({ start: $event })"
                      :value="list.period.date.range.start"
                      label=""
                      hint=""
                      :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')"
                      formatted="ddd, MMM D, YYYY, HH:mm"
                      format="YYYY-MM-DD HH:mm:ss"
                      :first-day-of-week="1"
                      input-size="sm"
                      :range="false"
                      :no-shortcuts="true"
                      :no-button="false"
                      :auto-close="false"
                    />
                  </div>
                </div>
              </div>
              <div class="grid-date" style="margin-left: 20px">
                <p class="grid-heading" style="color: inherit">
                  {{ $t('End date') }}
                </p>
                <div class="date-range flex-row">
                  <div class="input">
                    <VueCtkDateTimePicker
                      id="list-period-date-range-end"
                      @input="SearchDate({ end: $event })"
                      :value="list.period.date.range.end"
                      label=""
                      hint=""
                      :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')"
                      formatted="ddd, MMM D, YYYY, HH:mm"
                      format="YYYY-MM-DD HH:mm:ss"
                      :first-day-of-week="1"
                      input-size="sm"
                      :range="false"
                      :no-shortcuts="true"
                      :no-button="false"
                      :auto-close="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <a
              v-if="!list.period.cached"
              class="button"
              @click.prevent="GetList"
              href=""
              >{{ $t('Apply') }}</a
            >
            <div class="select period flex-column">
              <span class="label-text">{{ $t('Period') }}</span>
              <v-select
                name="period"
                @input="OnPeriodSelect"
                v-model="list.period.value"
                :options="list.period.options"
                :placeholder="$t('Period')"
                :clearable="false"
              >
                <template slot="option" slot-scope="option">
                  {{ $t(option.label) }}
                </template>
              </v-select>
            </div>
            <div class="toggle cost">
              <span class="label-text">{{ $t('Currency') }}</span>
              <div class="checkbox-toggle">
                <input
                  @click="ToggleCost"
                  type="checkbox"
                  :checked="list.cost"
                />
                <span class="toggle"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="simple-list summary">
          <ul class="simple-list__head no-sticky">
            <li class="list-col list-cell total-pickers">
              {{ $t('Total pickers') }}
            </li>
            <li class="list-col list-cell total-orders">
              {{ $t('Total orders') }}
            </li>
            <li class="list-col list-cell total-products">
              {{ $t('Total products') }}
            </li>
            <li class="list-col list-cell avg-time-pr-order">
              {{ $t('Avg. time per order') }}
            </li>
            <li class="list-col list-cell avg-time-pr-product">
              {{ $t('Avg. time per product') }}
            </li>
            <li class="list-col list-cell avg-cost-pr-order">
              {{ $t('Avg. cost per order') }}
            </li>
            <li class="list-col list-cell avg-cost-pr-product">
              {{ $t('Avg. cost per product') }}
            </li>
            <li class="list-col list-cell total-time">
              {{ $t('Total time') }}
            </li>
            <li class="list-col list-cell total-cost">
              {{ $t('Total cost') }}
            </li>
          </ul>
          <ul class="simple-list__body">
            <li style="font-weight: bold">
              <ul class="list-row head">
                <li class="list-col list-cell total-pickers">
                  {{ NumberFormat(list.entries) }}
                </li>
                <li class="list-col list-cell total-orders">
                  {{ NumberFormat(list.summary.total.order) }}
                </li>
                <li class="list-col list-cell total-products twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      {{ NumberFormat(list.summary.total.product.row) }}
                    </div>
                    <div class="twoinone-row">
                      {{ NumberFormat(list.summary.total.product.total) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell avg-time-pr-order">
                  {{ TimeFormat(list.summary.average.order.time) }}
                </li>
                <li class="list-col list-cell avg-time-pr-product twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      {{ TimeFormat(list.summary.average.product.row.time) }}
                    </div>
                    <div class="twoinone-row">
                      {{ TimeFormat(list.summary.average.product.total.time) }}
                    </div>
                  </span>
                </li>
                <li
                  class="list-col list-cell avg-cost-pr-order cost"
                  :class="{ show: list.cost }"
                >
                  Kr. {{ CurrencyFormat(list.summary.average.order.cost) }}
                </li>
                <li
                  class="list-col list-cell avg-cost-pr-product twoinone cost"
                  :class="{ show: list.cost }"
                >
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      Kr.
                      {{
                        CurrencyFormat(list.summary.average.product.row.cost)
                      }}
                    </div>
                    <div class="twoinone-row">
                      Kr.
                      {{
                        CurrencyFormat(list.summary.average.product.total.cost)
                      }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell total-time">
                  {{ TimeFormat(list.summary.total.time) }}
                </li>
                <li
                  class="list-col list-cell total-cost cost"
                  :class="{ show: list.cost }"
                >
                  Kr. {{ CurrencyFormat(list.summary.total.cost) }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="multi-dimensional-list">
          <ul class="multi-dimensional-list__head">
            <li class="list-col list-cell picker-name">{{ $t('Name') }}</li>
            <li class="list-col list-cell picking-period">
              {{ $t('Period') }}
            </li>
            <li class="list-col list-cell hourly-rate">
              {{ $t('Hourly rate') }}
            </li>
            <li class="list-col list-cell order-count">{{ $t('Orders') }}</li>
            <li class="list-col list-cell product-count">
              {{ $t('Products') }}
            </li>
            <li class="list-col list-cell time-pr-order">
              {{ $t('Time per order') }}
            </li>
            <li class="list-col list-cell time-pr-product">
              {{ $t('Time per product') }}
            </li>
            <li class="list-col list-cell cost-pr-order">
              {{ $t('Cost per order') }}
            </li>
            <li class="list-col list-cell cost-pr-product">
              {{ $t('Cost per product') }}
            </li>
            <li class="list-col list-cell total-time-tracked">
              {{ $t('Total time') }}
            </li>
            <li class="list-col list-cell total-cost-price">
              {{ $t('Total cost') }}
            </li>
            <li></li>
          </ul>
          <ul class="multi-dimensional-list__body odd-even">
            <li :key="item.user_id" v-for="item in list.items">
              <ul
                class="list-row head toggle collapsed"
                @click="ToggleListItem($event, item)"
              >
                <li class="list-col list-cell picker-name capitalize">
                  {{ item.user_name }}
                </li>
                <li class="list-col list-cell picking-period">
                  {{
                    DateRange({
                      start: item.period.start,
                      end: item.period.end && item.period.end,
                    }).string
                  }}
                </li>
                <li
                  class="list-col list-cell hourly-rate cost"
                  :class="{ show: list.cost }"
                >
                  Kr. {{ CurrencyFormat(item.user_hourly_pay) }}
                </li>
                <li class="list-col list-cell order-count">
                  {{ NumberFormat(item.sum.order_count) }}
                </li>
                <li class="list-col list-cell product-count twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      {{ NumberFormat(item.sum.product_count_row) }}
                    </div>
                    <div class="twoinone-row">
                      {{ NumberFormat(item.sum.product_count_total) }}
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell time-pr-order">
                  <span :title="item.data.length > 1 && $t('Average')">{{
                    TimeFormat(
                      item.sum.time_used_seconds / item.sum.order_count
                    )
                  }}</span>
                </li>
                <li class="list-col list-cell time-pr-product twoinone">
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <span :title="item.data.length > 1 && $t('Average')">{{
                        TimeFormat(
                          item.sum.time_used_seconds /
                            item.sum.product_count_row
                        )
                      }}</span>
                    </div>
                    <div class="twoinone-row">
                      <span :title="item.data.length > 1 && $t('Average')">{{
                        TimeFormat(
                          item.sum.time_used_seconds /
                            item.sum.product_count_total
                        )
                      }}</span>
                    </div>
                  </span>
                </li>
                <li
                  class="list-col list-cell cost-pr-order cost"
                  :class="{ show: list.cost }"
                >
                  <span :title="$t('Average')"
                    >Kr.
                    {{
                      CurrencyFormat(
                        CostPrHour(
                          item.user_hourly_pay,
                          item.sum.time_used_seconds
                        ) / item.sum.order_count
                      )
                    }}</span
                  >
                </li>
                <li
                  class="list-col list-cell cost-pr-product twoinone cost"
                  :class="{ show: list.cost }"
                >
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <b>{{ $t('Rows') }}</b
                      >:
                    </div>
                    <div class="twoinone-row">
                      <b>{{ $t('Total') }}</b
                      >:
                    </div>
                  </span>
                  <span class="twoinone-col">
                    <div class="twoinone-row">
                      <span :title="item.data.length > 1 && $t('Average')"
                        >Kr.
                        {{
                          CurrencyFormat(
                            CostPrHour(
                              item.user_hourly_pay,
                              item.sum.time_used_seconds
                            ) / item.sum.product_count_row
                          )
                        }}</span
                      >
                    </div>
                    <div class="twoinone-row">
                      <span :title="item.data.length > 1 && $t('Average')"
                        >Kr.
                        {{
                          CurrencyFormat(
                            CostPrHour(
                              item.user_hourly_pay,
                              item.sum.time_used_seconds
                            ) / item.sum.product_count_total
                          )
                        }}</span
                      >
                    </div>
                  </span>
                </li>
                <li class="list-col list-cell total-time-tracked">
                  {{ TimeFormat(item.sum.time_used_seconds) }}
                </li>
                <li
                  class="list-col list-cell total-cost-price cost"
                  :class="{ show: list.cost }"
                >
                  Kr.
                  {{
                    CurrencyFormat(
                      CostPrHour(
                        item.user_hourly_pay,
                        item.sum.time_used_seconds
                      )
                    )
                  }}
                </li>
              </ul>
              <ul class="list-row body">
                <li
                  class="list-col"
                  :key="index"
                  v-for="(data, index) in item.data"
                >
                  <ul class="list-row nested">
                    <li
                      class="list-col list-cell nested picker-name"
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <span
                        :class="[
                          'icon-inline link user-clock',
                          { inactive: !(data.breaks || []).length },
                        ]"
                        style="width: 20px; height: 20px; margin-right: 20px"
                        @click="DisplayWagonBreaks($event.target, data)"
                        :title="$t('Breaks')"
                      />
                      <span style="min-width: 104px"
                        >PLUK:{{ data.order_pick_list_id }}:{{
                          data.wagon
                        }}</span
                      >
                    </li>
                    <li
                      class="list-col list-cell nested picking-period"
                      style="line-height: initial"
                    >
                      <b style="font-weight: 600">{{
                        DateRange({
                          start: data.start_at,
                          end: data.completed_at,
                          time: true,
                          day: true,
                        }).parts.start.date
                      }}</b>
                      {{
                        DateRange({
                          start: data.start_at,
                          end: data.completed_at,
                          time: true,
                          day: true,
                        }).parts.start.time
                      }}
                      -
                      {{
                        DateRange({
                          start: data.start_at,
                          end: data.completed_at,
                          time: true,
                          day: true,
                        }).parts.end.time
                      }}
                    </li>
                    <li
                      class="list-col list-cell nested hourly-rate cost"
                      :class="{ show: list.cost }"
                    >
                      Kr. {{ CurrencyFormat(item.user_hourly_pay) }}
                    </li>
                    <li class="list-col list-cell nested order-count">
                      {{ NumberFormat(data.order_count) }}
                    </li>
                    <li
                      class="list-col list-cell nested product-count twoinone"
                    >
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          <b>{{ $t('Rows') }}</b
                          >:
                        </div>
                        <div class="twoinone-row">
                          <b>{{ $t('Total') }}</b
                          >:
                        </div>
                      </span>
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          {{ NumberFormat(data.product_count_row) }}
                        </div>
                        <div class="twoinone-row">
                          {{ NumberFormat(data.product_count_total) }}
                        </div>
                      </span>
                    </li>
                    <li class="list-col list-cell nested time-pr-order">
                      {{
                        TimeFormat(data.time_used_seconds / data.order_count)
                      }}
                    </li>
                    <li
                      class="list-col list-cell nested time-pr-product twoinone"
                    >
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          <b>{{ $t('Rows') }}</b
                          >:
                        </div>
                        <div class="twoinone-row">
                          <b>{{ $t('Total') }}</b
                          >:
                        </div>
                      </span>
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          {{
                            TimeFormat(
                              data.time_used_seconds / data.product_count_row
                            )
                          }}
                        </div>
                        <div class="twoinone-row">
                          {{
                            TimeFormat(
                              data.time_used_seconds / data.product_count_total
                            )
                          }}
                        </div>
                      </span>
                    </li>
                    <li
                      class="list-col list-cell nested cost-pr-order cost"
                      :class="{ show: list.cost }"
                    >
                      Kr.
                      {{
                        CurrencyFormat(
                          CostPrHour(
                            item.user_hourly_pay,
                            data.time_used_seconds
                          ) / data.order_count
                        )
                      }}
                    </li>
                    <li
                      class="list-col list-cell nested cost-pr-product twoinone cost"
                      :class="{ show: list.cost }"
                    >
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          <b>{{ $t('Rows') }}</b
                          >:
                        </div>
                        <div class="twoinone-row">
                          <b>{{ $t('Total') }}</b
                          >:
                        </div>
                      </span>
                      <span class="twoinone-col">
                        <div class="twoinone-row">
                          Kr.
                          {{
                            CurrencyFormat(
                              CostPrHour(
                                item.user_hourly_pay,
                                data.time_used_seconds
                              ) / data.product_count_row
                            )
                          }}
                        </div>
                        <div class="twoinone-row">
                          Kr.
                          {{
                            CurrencyFormat(
                              CostPrHour(
                                item.user_hourly_pay,
                                data.time_used_seconds
                              ) / data.product_count_total
                            )
                          }}
                        </div>
                      </span>
                    </li>
                    <li class="list-col list-cell nested total-time-tracked">
                      {{ TimeFormat(data.time_used_seconds) }}
                    </li>
                    <li
                      class="list-col list-cell nested total-cost-price cost"
                      :class="{ show: list.cost }"
                    >
                      Kr.
                      {{
                        CurrencyFormat(
                          CostPrHour(
                            item.user_hourly_pay,
                            data.time_used_seconds
                          )
                        )
                      }}
                    </li>
                    <button class="button" @click="GenerateWarehouseMap(data.order_pick_list_id, data.wagon)">{{ $t('Get Map') }}</button>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="loading" v-if="loading">
        <div class="loading-animation">
          <div class="loading-element">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="loading-information" :class="{ show: abort.show }">
          <a
            class="button abort"
            @click.prevent="abort.controller.abort()"
            href=""
          >
            Abort
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormElementsBehaviour } from '@/mixins/FormElementsBehaviour';
import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';
//import { LagerMap } from '../../../js/lager_map_editor';

export default {
  name: 'StatisticsPickerStats',
  mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
  components: {},
  data() {
    return {
      loading: false,
      abort: {
        show: false,
        delay: 5000,
        timer: null,
        timeout: null,
        controller: null,
      },
      list: {
        entries: 0,
        items: {},
        summary: {},
        toggles: [],
        observers: [],
        period: {
          value: '',
          cached: true,
          default: { value: '7_day', label: 'Last 7 days incl. today' },
          selected: { value: '7_day', label: 'Last 7 days incl. today' },
          options: [
            { value: '1_day', label: 'Yesterday and today' },
            { value: '7_day', label: 'Last 7 days incl. today' },
            { value: '30_day', label: 'Last 30 days incl. today' },
            { value: 'custom', label: 'Date range' },
          ],
          date: {
            disabled: true,
            range: {
              start: null,
              end: null,
            },
          },
        },
        sort: {
          dir: 'desc',
          by: '',
        },
        cost: true,
      },
    };
  },
  created() {
    this.GetList();
    this.ResetListSummary();
    this.list.period.value = this.$t(this.list.period.selected.label);
  },
  mounted() {},
  destroyed() {
    this.UnobserveIntersections();
  },
  computed: {
    main() {
      const main = {
        company: BPA.util.GetCompany(),
        view: document.querySelector('.content-scroll'),
      };
      main.view.scroll = { top: 0 };
      return main;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.list.period.value = this.$t(this.list.period.selected.label);
    },
  },
  methods: {
    DateFormat(date) {
      return Tool.DateFormat(date);
    },
    DateRange(props) {
      return Tool.DateRange(props);
    },
    DateOnly(date) {
      if (!date) return;
      date = this.DateFormat(date).replace(this.TimeOnly(date), '');
      return date.replace(/,(?=[^,]*$)/, '');
    },
    TimeOnly(date) {
      return Tool.TimeOnly(date);
    },
    /*
      TimeFormat(seconds = 0, all_parts = false) {
        let time = Tool.TimeFormat(seconds, all_parts).split(' ').map(x => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, ''))).join(' ');
        return time ? time : ('00' + this.$t('s'));
      },
      */
    TimeFormat(seconds = 0, all_parts = false, colon_divided = false) {
      let time = Tool.TimeFormat(seconds, all_parts, colon_divided);
      if (!colon_divided) {
        time = time
          .split(' ')
          .map((x) => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, '')))
          .join(' ');
        time = time ? time : '00' + this.$t('s');
      }
      return time;
    },
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    CurrencyFormat(number) {
      return Tool.CurrencyFormat(number);
    },
    Empty(element) {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    },
    async SearchDate(date) {
      let type = Object.keys(date)[0];
      let range = this.list.period.date.range;
      if (date[type] && !range[type]) {
        let time = (type == 'start' ? '00:00' : '23:59') + ':00';
        date[type] = date[type].split(' ')[0] + ' ' + time;
      }
      range[type] = date[type];
    },
    OnPeriodSelect(option) {
      const cached = () => {
        //if (!option) this.list.period.selected = this.list.period.default;
        const clear_buttons = document.querySelectorAll('.field-clear-button');
        clear_buttons && clear_buttons.forEach((button) => button.click());
        this.list.period.date.disabled = true;
        this.list.period.cached = true;
        this.GetList();
      };
      const custom = () => {
        this.list.period.date.disabled = false;
        this.list.period.value = this.$t('Date range');
        this.list.period.cached = false;
      };
      this.list.period.selected = option;
      this.list.period.value = this.$t(this.list.period.selected.label);
      option ? (option.value == 'custom' ? custom() : cached()) : cached();
    },
    GetList() {
      let period = this.list.period;
      /*
        if (cached) {
          this.loading = true;
          const cache_name = period.selected.value;
          BPA.api.GetPickerStatsFromCache(cache_name).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            let items = response.result || {};
            const entries = Object.keys(items).length;
            const summary = this.ResetListSummary();
            this.list.entries = entries;
            this.list.items = {};
            if (!entries) return;
            for (let entry in items) {
              const item = items[entry];
              item.sum = {};
              for (let data of item.data) {
                for (let prop in data) {
                  if (prop in item.sum) {
                    item.sum[prop] += data[prop];
                  } else {
                    item.sum[prop] = data[prop];
                  }
                }
              }
              summary.total.order += item.sum.order_count;
              summary.total.product.row += item.sum.product_count_row;
              summary.total.product.total += item.sum.product_count_total;
              summary.total.time += item.sum.time_used_seconds;
              summary.total.cost += this.CostPrHour(item.user_hourly_pay, item.sum.time_used_seconds)
            }
            summary.average.order.time = summary.total.time / summary.total.order;
            summary.average.product.row.time = summary.total.time / summary.total.product.row;
            summary.average.product.total.time = summary.total.time / summary.total.product.total;
            summary.average.order.cost = summary.total.cost / summary.total.order;
            summary.average.product.row.cost = summary.total.cost / summary.total.product.row;
            summary.average.product.total.cost = summary.total.cost / summary.total.product.total;
            this.list.items = items;
            this.list.summary = summary;
            //console.log('summary', JSON.parse(JSON.stringify(summary)))
            //console.log('items', JSON.parse(JSON.stringify(items)))
            this.UpdateAllNestedListHeights();
            this.$nextTick().then(this.ObserveIntersections);
          }).catch(e => e);
          return;
        }
        */
      let cached = period.cached;
      let cache_name = period.selected.value;
      let range = period.date.range;
      let params = {
        start_date: new Date(range.start),
        end_date: new Date(range.end),
      };
      if (cached) {
        let date = new Date();
        let today = new Date();
        params.start_date = date;
        params.end_date = new Date();
        params.start_date = new Date(
          today.setDate(today.getDate() - parseInt(cache_name))
        );
        params.start_date.setHours(0, 0, 0);
        params.end_date.setHours(23, 59, 59);
        ['start', 'end'].map((date) => (range[date] = true));
      }
      if (range.start && range.end) {
        if (typeof range.start == 'boolean') {
          ['start', 'end'].map((date) => (range[date] = ''));
        }
        if (params.end_date > params.start_date) {
          this.abort.controller = new AbortController();
          params.start_date = Tool.DateToUTC(params.start_date);
          params.end_date = Tool.DateToUTC(params.end_date);
          params.signal = this.abort.controller.signal;
          clearTimeout(this.abort.timeout);
          clearTimeout(this.abort.timer);
          this.abort.timeout = setTimeout(() => {
            this.abort.controller.abort();
          }, 300000);
          this.abort.timer = setTimeout(() => {
            this.abort.show = true;
          }, this.abort.delay);
          this.loading = true;
          //console.log(params)
          BPA.api
            .GetPickerStats(params)
            .then((response) => {
              return BPA.api.response({ response, return: 'json' });
            })
            .then((response) => {
              clearTimeout(this.abort.timeout);
              clearTimeout(this.abort.timer);
              this.abort.show = false;
              this.loading = false;
              if (!response.ok) return;
              let items = response.result || {};
              const entries = Object.keys(items).length;
              const summary = this.ResetListSummary();
              this.list.entries = entries;
              this.list.items = {};
              if (!entries) return;
              for (let entry in items) {
                const item = items[entry];
                //console.log(Tool.CloneObject(item))
                let item_dates = [];
                item.period = {};
                item.sum = {};
                for (let i = 0; i < item.data.length; i++) {
                  let data = item.data[i];
                  for (let prop in data) {
                    if (/_at$/.test(prop)) {
                      if (i == 0 || i == item.data.length - 1) {
                        item_dates.push(data.start_at);
                      }
                    } else {
                      if (prop in item.sum) {
                        item.sum[prop] += data[prop];
                      } else {
                        item.sum[prop] = data[prop];
                      }
                    }
                  }
                }
                item.period.start = item_dates[0];
                item.period.end = item_dates.pop();
                if (item.period.start == item.period.end) {
                  item.period.end = null;
                }
                summary.total.order += item.sum.order_count;
                summary.total.product.row += item.sum.product_count_row;
                summary.total.product.total += item.sum.product_count_total;
                summary.total.time += item.sum.time_used_seconds;
                summary.total.cost += this.CostPrHour(
                  item.user_hourly_pay,
                  item.sum.time_used_seconds
                );
              }
              summary.average.order.time =
                summary.total.time / summary.total.order;
              summary.average.product.row.time =
                summary.total.time / summary.total.product.row;
              summary.average.product.total.time =
                summary.total.time / summary.total.product.total;
              summary.average.order.cost =
                summary.total.cost / summary.total.order;
              summary.average.product.row.cost =
                summary.total.cost / summary.total.product.row;
              summary.average.product.total.cost =
                summary.total.cost / summary.total.product.total;
              this.list.items = items;
              this.list.summary = summary;
              //console.log('summary', JSON.parse(JSON.stringify(summary)))
              //console.log('items', JSON.parse(JSON.stringify(items)))
              this.UpdateAllNestedListHeights();
              this.$nextTick().then(this.ObserveIntersections);
            })
            .catch((e) => e);
        }
      }
    },
    ResetListSummary() {
      this.list.summary = {
        total: {
          order: 0,
          product: {
            row: 0,
            total: 0,
          },
          time: 0,
          cost: 0,
        },
        average: {
          order: {
            time: 0,
            cost: 0,
          },
          product: {
            row: {
              time: 0,
              cost: 0,
            },
            total: {
              time: 0,
              cost: 0,
            },
          },
        },
      };
      return JSON.parse(JSON.stringify(this.list.summary));
    },
    DisplayWagonBreaks(icon, data) {
      if (icon.classList.contains('displaying')) return;
      let node = {
        div: document.createElement('div'),
        table: document.createElement('table'),
        thead: document.createElement('thead'),
        tbody: document.createElement('tbody'),
        tr: document.createElement('tr'),
        th: document.createElement('th'),
        td: document.createElement('td'),
        keys: {
          type: 'type',
          date: 'date',
          start: 'started_at',
          end: 'completed_at',
          duration: 'duration',
          percent: 'percent',
        },
      };
      node.table.className = 'table odd-even';
      icon.rect = icon.getBoundingClientRect();
      for (let name of ['arrow', 'container', 'wrapper', 'list']) {
        let div = (node[name] = node.div.cloneNode());
        div.className = name;
        if (name == 'list') {
          node.thead.tr = node.tr.cloneNode();
          for (let key in node.keys) {
            let th = node.th.cloneNode();
            th.textContent = this.$t(Tool.Capitalize(key));
            node.thead.tr.append(th);
          }
          node.thead.append(node.thead.tr);
          node.table.append(node.thead);
          /*
            let totalSeconds = (time = '00:00:00') => 
              let parts = time.split(':');
              return parts[0] * 3600 + parts[1] * 60 + parts[2];
            }
            */
          let counter = 0;
          let iterations = data.breaks.length;
          for (let pause of data.breaks) {
            let tr = node.tr.cloneNode();
            let duration = Tool.DateTimeDiff(
              pause.started_at,
              pause.completed_at
            );
            for (let key of Object.values(node.keys)) {
              let td = node.td.cloneNode();
              if (key == 'type') {
                td.textContent = this.$t(
                  Tool.Capitalize(pause[key].toLowerCase())
                );
              }
              if (key == 'date') {
                td.textContent = this.DateOnly(pause.started_at);
              }
              if (/at$/.test(key)) {
                td.textContent = this.TimeOnly(pause[key]);
              }
              if (key == 'duration') {
                td.textContent = this.TimeFormat(duration, false, false);
              }
              if (key == 'percent') {
                td.textContent =
                  this.NumberFormat(
                    ((100 * duration) / data.time_used_seconds).toFixed(2)
                  ) + '%';
              }
              tr.append(td);
            }
            if (!--iterations && counter % 2 == 0)
              Object.assign(tr.style, {
                boxShadow: '0 1px 0 0 rgba(211, 211, 211, 0.75)',
              });
            node.tbody.append(tr);
            counter++;
          }
          node.table.append(node.tbody);
          node.list.append(node.table);
        }
      }
      delete node.div;
      node.wrapper.append(node.list);
      node.container.append(node.arrow, node.wrapper);
      Object.assign(node.arrow.style, {
        top: '20px',
        left: '1px',
        zIndex: 1,
        width: '20px',
        height: '20px',
        position: 'absolute',
        clipPath: 'polygon(100% 0%, 40% 50%, 100% 100%)',
        transform: 'translateX(-100%)',
        backgroundColor: 'white',
      });
      Object.assign(node.container.style, {
        top: icon.rect.top - 20 + 'px',
        left: icon.rect.left + icon.rect.width + 20 + 'px',
        //boxShadow: '2px 4px 10px 0 rgba(0, 0, 0, 0.25)',
        filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))',
        backgroundColor: 'white',
        borderRadius: '10px',
        position: 'fixed',
        padding: '10px 20px 20px',
        zIndex: 1,
      });
      document.body.append(node.container);
      icon.classList.add('displaying');
      let event = {
        mousedown: (e) => {
          if (e.target != icon && !node.container.contains(e.target)) {
            window.removeEventListener('mousedown', event.mousedown);
            this.main.view.removeEventListener('scroll', event.scroll);
            icon.classList.remove('displaying');
            node.container.remove();
          }
        },
        scroll: () => {
          node.container.style.top =
            icon.getBoundingClientRect().top - 20 + 'px';
        },
      };
      window.addEventListener('mousedown', event.mousedown);
      this.main.view.addEventListener('scroll', event.scroll);
    },
    async GetWagonBreaks(params = {}) {
      let data = null;
      let items = this.list.items;
      let keys = Object.keys(items);
      for (let key of keys) {
        for (let pause of items[key].data) {
          if (
            pause.order_pick_list_id == params.pick_list &&
            params.wagon == params.wagon
          ) {
            data = pause;
            break;
          }
        }
        if (data) break;
      }
      if (data.breaks) return;
      return await BPA.api
        .GetPickerStatsWagonBreaks(params)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          if (!response.ok) return;
          data.breaks = response.result;
        })
        .catch((e) => e);
    },
    async ToggleListItem(event, item) {
      let element = event.target;
      if (!element.classList.contains('toggle')) {
        element = element.closest('.toggle');
        if (!element) return;
      }
      const collapsed = element.classList.contains('collapsed');
      const nested = element.nextSibling;
      if (collapsed) {
        if (item.data.length) {
          let icons = nested.querySelectorAll('.user-clock');
          this.loading = true;
          for (let i = 0; i < item.data.length; i++) {
            await this.GetWagonBreaks({
              pick_list: item.data[i].order_pick_list_id,
              wagon: item.data[i].wagon,
              icon: icons[i],
            });
          }
          this.loading = false;
        }
        this.SetNestedListHeight(nested);
      } else {
        nested.style.removeProperty('height');
      }
      clearTimeout(this.list.animate);
      nested.classList.add('animate');
      ['collapsed', 'expanded'].map((class_name) => {
        element.classList.toggle(class_name);
      });
      this.list.animate = setTimeout(() => {
        nested.classList.remove('animate');
      }, 150);
    },
    SetNestedListHeight(element) {
      element.style.removeProperty('height');
      let clone = element.cloneNode(true);
      clone.classList.add('clone');
      element.parentElement.append(clone);
      let rect = clone.getBoundingClientRect();
      element.style.height = rect.height + 'px';
      clone.remove();
    },
    UpdateAllNestedListHeights() {
      setTimeout(() => {
        const items = document.querySelectorAll('.expanded ~ .body');
        items.forEach(this.SetNestedListHeight);
      });
    },
    CostPrHour(user_hourly_pay, time_used_seconds) {
      return user_hourly_pay * (time_used_seconds / 3600).toFixed(2);
    },
    ToggleCost(e) {
      this.list.cost = e.target.checked;
    },
    ObserveIntersections() {
      this.UnobserveIntersections();
      document
        .querySelectorAll('.multi-dimensional-list .toggle')
        .forEach((toggle) => {
          const observer = new IntersectionObserver(
            ([e]) => {
              e.target.toggleAttribute('stuck', e.intersectionRatio < 1);
            },
            { rootMargin: '-35px 0px 0px 0px', threshold: 1 }
          );
          observer.observe(toggle);
          this.list.toggles.push(toggle);
          this.list.observers.push(observer);
        });
    },
    UnobserveIntersections() {
      this.list.toggles.map((toggle, i) => {
        this.list.observers[i].unobserve(toggle);
      });
      this.list.observers = [];
      this.list.toggles = [];
    },
    async GetPicklist(list_id) {
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetPicklist(list_id)
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok) return reject();

            resolve(response.result);
          })
          .catch(reject);
      }).catch((e) => e);
    },
    async GenerateWarehouseMap(list_id, wagon_number) {
      
      let map_link = await new Promise((resolve, reject) => {
        BPA.api
          .GetDrawWarehouseMapLink(list_id, wagon_number)
          .then((response) => {
            return BPA.api.response({ response, return: 'text' });
          })
          .then((response) => {
            if (!response.ok) return reject();

            resolve(response.result);
          })
          .catch(reject);
      }).catch((e) => e);

      window.open(BPA.api.base().replace('/api/beautycos','') + map_link)
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/style/variables/colors';
@import '../../../assets/style/variables/icons';

.grid-info {
  flex-wrap: nowrap;

  .grid-heading {
    flex-shrink: 0;
  }

  &.flex-center {
    align-items: center;
    //margin-bottom: 85px;

    .grid-actions {
      width: 100%;
      justify-content: flex-end;
      margin: 0 0 0 calc(10% + 20px);

      .select.period {
        width: 100%;
        max-width: 280px;
      }

      .date-range {
        width: 100%;

        .input {
          width: 100%;
        }
      }

      .button {
        height: 100%;
        flex-shrink: 0;
        background-color: $green;
      }

      > * + * {
        margin-left: 20px;
      }
    }
  }
}

.total-pickers {
  width: 8%;
}
.total-orders {
  width: 8%;
}
.total-products {
  width: 9%;
}
.avg-time-pr-order {
  width: 11%;
}
.avg-time-pr-product {
  width: 12%;
}
.avg-cost-pr-order {
  width: 11%;
}
.avg-cost-pr-product {
  width: 13%;
}
.total-time {
  width: 7%;
}
.total-cost {
  width: 7%;
}

.picker-name {
  width: 11%;
}
.picking-period {
  width: 10%;
}
.hourly-rate {
  width: 6%;
}
.order-count {
  width: 6%;
}
.product-count {
  width: 6%;
}
.time-pr-order {
  width: 8%;
}
.time-pr-product {
  width: 9%;
}
.cost-pr-order {
  width: 8%;
}
.cost-pr-product {
  width: 10%;
}
.total-time-tracked {
  width: 7%;
}
.total-cost-price {
  width: 6%;
}

.multi-dimensional-list__body .list-row:not(.body).head {
  top: 34px;
}

.twoinone {
  display: flex;
  line-height: 1.2em;
  flex-direction: row;

  &-col {
    b {
      font-weight: 600;
    }

    & + .twoinone-col {
      margin-left: 6px;
    }
  }
}
.button {
  margin: 5px;
}
</style>
